import axios from 'axios'
import router from '@/router'
import {objPropTrim} from '@/utils'
import { getToken ,getTenantcode} from '@/utils/authentication'
import {ElNotification,ElMessageBox } from 'element-plus'
// import store from '@/store'

// 禁用IE下get请求缓存
axios.defaults.headers.get['Cache-Control'] = 'no-cache'
axios.defaults.headers.get.Pragma = 'no-cache'
// 超时时间
axios.defaults.timeout = 30000
// 跨域请求，允许保存cookie
axios.defaults.withCredentials = true
axios.defaults.headers = {'Content-Type': 'application/json; charset=utf-8'}
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
// const BASE_URL = process.env.NODE_ENV !== 'production' && process.env.OPEN_PROXY ? '/platform-plus' : window.SITE_CONFIG.baseUrl
// 对面暴露的基础请求路径
// axios.baseURL='http://localhost:8080'
// axios.applicationContext="http://localhost:8080/homework"
// axios.defaults.baseURL = 'http://localhost:8080/homework'
axios.baseURL='http://1.94.67.84'
axios.defaults.baseURL = 'http://1.94.67.84/homework'
axios.applicationContext='http://1.94.67.84/homework'
/**
 * 请求拦截
 */

axios.interceptors.request.use(config => {
  // 参数去除空格
  if (config.data) {
    objPropTrim(config.data)
  }
  if (config.params) {
    objPropTrim(config.params)
  }
  return config
  
}, error => {
  return Promise.reject(error)
})

/**
 * 响应拦截
 */
axios.interceptors.response.use(response => {
  //无权限
  if(response.data.result==401){
    ElMessageBox.confirm(
      '会话已过期，请重新登录！',
        '提示',
        {
            confirmButtonText: '确定',
             cancelButtonText: '取消',
             type: 'warning',
         }
       )
     .then(() => {
      router.push({name: 'login'})
      })
      .catch(() => {
         })
  }
  // loading.close()
  // 如果access_tokn为空，弹出错误消息，并导向登录界面
  if (!(response.status === 200)) {
    ElNotification({
      title: '提示',
      type: 'error',
      message: response.data.msg,
      duration: 3000,
    })
    router.push({name: 'login'})
  }
  return response
}, error => {
 
  ElNotification({
    title: '提示',
    type: 'error',
    showClose: false,
    message: '网络异常，请稍后重试',
    duration: 3000,
  })
  return Promise.reject(error)
})

export default axios

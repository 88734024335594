import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [

  {
    path: '/',
    name: 'home',
    component: HomeView
  },{
    path: '/boarder',
    name: 'boarder',
    component:  () => import('../views/boarder/boarder.vue'),
  },{
    path: '/myhomework',
    name: 'myhomework',
    component:  () => import('../views/myhomework/myHomework.vue'),
  },{
    path: '/jiaoxueresources',
    name: 'jiaoxueresources',
    component:  () => import('../views/jiaoxueresources/main.vue'),
  },{
    path: '/jiaoxueshijian',
    name: 'jiaoxueshijian',
    component:  () => import('../views/jiaoxueshijian/main.vue'),
  },{
    path: '/wendashequ',
    name: 'wendashequ',
    component:  () => import('../views/wendashequ/main.vue'),
  },{
    path: '/mycourses',
    name: 'mycourses',
    component:  () => import('../views/mycourses/main.vue'),
  },
  {
    path: '/video/:id',
    name: 'video',
    component:  () => import('../views/video/video.vue'),
  }, {
    path: '/showShiJianInfo/:id',
    name: 'showShiJianInfo',
    component:  () => import('../views/jiaoxueshijian/showShiJianInfo.vue'),
  },{
    path: '/xuanti',
    name: 'xuanti',
    component:  () => import('../views/xuanti/main.vue'),
  },{
    path: '/dabian',
    name: 'dabian',
    component:  () => import('../views/dabian/main.vue'),
  },{
    path: '/login',
    name: 'login',
    component:  () => import('../views/home/login.vue'),
  },{
    path: '/courseinfo/:id/:name/:label',
    name: 'courseinfo',
    component:  () => import('../views/mycourses/courseinfo.vue'),
  },{
    path: '/homework/:id/:name/:label',
    name: 'homework',
    component:  () => import('../views/mycourses/test.vue'),
  },{
    path: '/lessonplan/:id/:name/:label',
    name: 'lessonplan',
    component:  () => import('../views/mycourses/lessonPlan.vue'),
  },{
    path: '/student/:id/:name/:label',
    name: 'student',
    component:  () => import('../views/mycourses/student.vue'),
  },{
    path: '/lessonjiaoxue',
    name: 'lessonjiaoxue',
    component:  () => import('../views/mycourses/lesson-jiaoxue.vue'),
  },{
    path: '/lessonxuexi',
    name: 'lessonxuexi',
    component:  () => import('../views/mycourses/lesson-xuexi.vue'),
  },{
    path: '/graph/:id/:name/:label',
    name: 'graph',
    component:  () => import('../views/mycourses/graph.vue'),
  },{
    path: '/graphstudent/:id/:name/:label',
    name: 'graphstudent',
    component:  () => import('../views/mycourses/graph_student.vue'),
  },{
    path: '/graphstudentstudy',
    name: 'graphstudentstudy',
    component:  () => import('../views/mycourses/graph_student_study.vue'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
 
export default router

<template>
  <div class="common-layout" style="height:100%;width:100%;">
    <el-container >
      <el-header style="border-bottom: 8px solid rgb(220, 231, 245);height: 70px;padding-top: 10px;padding-right: 0px;padding-left: 10px;">
        <el-row style="height:100%;padding-top: 0px;" >
            <el-col :span="5"  class="logo" >
                <div style="height:100%;padding-top: 10px;padding-bottom: 10px;"> 
                    <span style="font-size:16px;">教学辅助平台</span>
                </div>
            </el-col>
               
            <el-col :span="14" style="height: 100%;padding-left: 50px;border: 0px solid red;padding-right: 0px;">
                <el-menu
  :default-active="activeIndex" 
  mode="horizontal"
  @select="handleSelect"
  style="height:100%;"
>
  <el-menu-item index="1" style="padding:10px"><span style="font-size:16px;color: rgb(143, 140, 140);">首页</span></el-menu-item>
  <el-menu-item index="2" style="padding:10px"><span style="font-size:16px;color: rgb(143, 140, 140);">资源库</span></el-menu-item>
  <el-menu-item index="3" style="padding:10px" ><span style="font-size:16px;color: rgb(143, 140, 140);">教学实践</span></el-menu-item>
  <el-menu-item index="4" style="padding:10px"><span style="font-size:16px;color: rgb(143, 140, 140);">我的课程</span></el-menu-item>
  <el-menu-item index="5" style="padding:10px"><span style="font-size:16px;color: rgb(143, 140, 140);">我的作业</span></el-menu-item>
  <el-sub-menu >
    <template #title><span style="font-size:16px">毕业设计</span></template>
     <el-menu-item index="7" style="padding-left:10px;"><span style="font-size:14px;color: rgb(143, 140, 140);"> <el-icon><location /></el-icon>我的选题</span></el-menu-item>
     <el-menu-item index="8" style="padding-left:10px;"><span style="font-size:14px;color: rgb(143, 140, 140);"> <el-icon><location /></el-icon>答辩申请</span></el-menu-item>
</el-sub-menu>
 <el-menu-item index="6" style="padding:10px"><span style="font-size:16px;color: rgb(143, 140, 140);">关于我们</span></el-menu-item>
 
</el-menu>

            </el-col>
            <el-col :span="5" style="padding: 0px;padding-bottom: 0px;border: 0px solid red;width: 100%;">
           <template v-if="!ishaslogin" >
                <el-button  type="primary" @click="toLogin()" style="margin-top:10px"> 
     登 录</el-button>
<!-- <el-button style="margin-top:10px">注 册</el-button>  -->
</template>
      <el-dropdown 
       v-show="ishaslogin"   
       trigger="click"
       split-button
       plain  
      @click="handleClick" 
      style="width:100%;height: 90%;margin-top: 10px;" >
        <span style="font-size:16px">
          <el-icon><UserFilled/></el-icon>
              {{ userName}}
              {{ userRealName }}
            </span>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item  @click="updatePass()">修改密码</el-dropdown-item>
          <el-dropdown-item @click="backSys()"> 退 出</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
            </el-col>
        </el-row>
      </el-header>
      <el-main class="main" >
        <router-view></router-view>
      </el-main>
      <el-footer style="border-top: 3px solid rgb(203, 218, 238);">
        <div style="text-align: center">
						<span  style="font-size: 14px;color: #1b5050;">
						&nbsp;&nbsp;&nbsp;&nbsp;
						地 址：山东青岛经济技术开发区 &nbsp;
						联系电话：13455235097&nbsp;
						电子信箱：911845094@qq.com&nbsp;
          </span>
					   <br>
							<a href="http://beian.miit.gov.cn/" target="_blank"
							style="text-decoration: none;color:#1b5050; height: 15px;">
              <span style="font-size: 12px;">鲁ICP备2023018886号</span>
							</a>
              &nbsp;&nbsp;
              <a href="https://beian.mps.gov.cn/#/query/webSearch?recordcode=37021002001173" target="_blank"
							style="text-decoration: none;color:#1b5050; ">
              <span style="font-size: 12px;">   
                <img
             style="width: 15px; height: 15px;margin: 0px;padding: 0px;"
               src="./assets/img/jinghui.png"
              >
                鲁公网安备37021002001173号</span>
							</a>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <a href="http://1.94.67.84/homework/login/login.do" target="_blank"
							style="color:#4821d6; font-size: 14px;">
								=>作业管理后台入口
							</a>
						
				</div>
      </el-footer>
    </el-container>
  </div>
  <loginView v-show="showLoginView" ref="loginViewDialog" @refreshDataList="refreshDataList"></loginView>
  <updatePass v-show="showUpdatePass" ref="updatePassView" @refreshDataList="refreshLogindate"></updatePass>
</template>

<script>
import { getUsername ,setUsername,removeUsername,getRealUsername,removeRealUsername, getTenantcode,removeUserId} from '@/utils/authentication'
import { useThrottledRefHistory } from '@vueuse/core';
import loginView from './views/home/loginDialog.vue'
import updatePass from './views/home/updatePass.vue'
export default {
  data(){
return{
  showLoginView:false,
  showUpdatePass:false,
  ishaslogin:false,//是否已经登录
  userName:'',
  userRealName:'',
  tenentName:''
}
  },
  components:{
    loginView,
    updatePass
  },
  created () {
  },
  mounted(){
    // window.addEventListener('beforeunload', function(event) {
    //       // 在窗口关闭之前执行的操作
    //       // 可以在这里添加清除Cookie、保存数据等操作
    //       this.backSys()
    //       event.preventDefault() // 阻止默认的关闭行为
    //       event.returnValue = '' // Chrome需要设置returnValue属性
    //  });
    if(getUsername()){
       this.refreshDataList()
    }
  },
   methods:{
    getUsername(){
      return getUsername()
    },
    getRealUsername(){
      return getRealUsername()
    },
  //修改密码
    updatePass(){
      this.showUpdatePass=true
      this.$nextTick(() => {
          this.$refs.updatePassView.init()
        })
    },
    // 退出系统
    backSys(){
      removeUsername()
      removeRealUsername()
      removeUserId()
      this.ishaslogin=false
      this.$router.push({path:'/'})
    },
    // 刷新登录信息，重新登录
    refreshLogindate(){
      removeUsername()
      removeRealUsername()
      removeUserId()
      this.ishaslogin=false
      this.showLoginView=true
      this.$nextTick(() => {
          this.$refs.loginViewDialog.init()
        })
    },
    //刷新数据
    refreshDataList(){
      this.ishaslogin=true;
      this.userName=getUsername()
      this.userRealName=getRealUsername()
    },
    //菜单事件
    handleSelect(index){
    if(index==1){//首页
      this.$router.push({name:"home"})
    }else if(index==2){//资源库
         this.$router.push({name:"jiaoxueresources"})
    }else if(index==3){//教学实践
      // 是否登录
      if(this.ishaslogin){
         this.$router.push({name:"jiaoxueshijian"})
      }else{
        this.toLogin('jiaoxueshijian')
      }
    }else if(index==4){//我的课程
         // 是否登录
         if(this.ishaslogin){
          this.$router.push({name:"mycourses"})
      }else{
        this.toLogin('mycourses')
      }
     
    }else if(index==5){//我的作业
      // 是否登录
      if(this.ishaslogin){
        this.$router.push({name:"myhomework"})
      }else{
        this.toLogin('myhomework')
      }
    } else if(index==6){//关于我们
        this.$router.push({name:"about"})
    }else if(index==7){//毕设选题
      // 是否登录
      if(this.ishaslogin){
         this.$router.push({name:"xuanti"})
      }else{
        this.toLogin('xuanti')
      }
       
    }else if(index==8){//毕设答辩
      // 是否登录
      if(this.ishaslogin){
         this.$router.push({name:"dabian"})
      }else{
        this.toLogin('dabian')
      }
       
    }
    },
    toLogin(pathname){
      this.showLoginView=true
      this.$nextTick(() => {
          this.$refs.loginViewDialog.init(pathname)
        })
    },
    isLogin(){
      if(getUsername()){
        return true
      }else{
        return false
      }
    }
   }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.logo{
    background-image: url("./assets/img/etrex.png");
    height: 100%;
    background-repeat: no-repeat;
    background-size: 60% 50px;
    text-align: right;
}
.main{
  padding:0px !important;
  margin:0px !important;
  padding-right:5px  !important;
  padding-left:5px  !important;
}

</style>
